import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, Avatar } from '@mui/material';
import { IconMenu2 } from '@tabler/icons';
import moment from 'moment';

// project imports
import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';
import { useEffect, useState } from 'react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        window.currentDate = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return clearInterval(window.currentDate);
    });

    return (
        <>
            <ButtonBase sx={{ borderRadius: '12px', mr: 2 }} onClick={handleLeftDrawerToggle}>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.secondary.dark,
                            color: theme.palette.secondary.light
                        },
                        [theme.breakpoints.up('lg')]: { display: 'none' }
                    }}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </Avatar>
            </ButtonBase>
            <Box sx={{ bgcolor: theme.palette.secondary.light, px: 2, py: 2, borderRadius: 4 }}>
                <Box sx={{ color: theme.palette.secondary.dark, fontSize: 28, fontWeight: 700 }}>{moment(currentDate).format('HH:mm')}</Box>
            </Box>
            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func.isRequired
};

export default Header;
