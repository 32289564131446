const standardParams = {
    status: 'Status',
    optik: 'Optik',
    order_by: 'Order By',
    order_date: 'Order Date',
    ref_number: 'Ref Number',
    customer_name: 'Customer Name',
    lens: 'Lensa',
    color_group: 'Color Group',
    tinting_color: 'Tinting Color',
    tinting_density: 'Tinting Density',
    coating: 'Coating',
    paket: 'Paket Lensa',
    jarak_baca: 'Jarak baca',
    pd: 'Vertex Distance',
    pt: 'Panthoscopic',
    wa: 'Wrap',
    lens_type: 'Lens Group',
    r_sph: 'Right SPH',
    l_sph: 'Left SPH',
    r_cyl: 'Right CYL',
    l_cyl: 'Left CYL',
    r_axis: 'Right Axis',
    l_axis: 'Left Axis',
    r_add: 'Right ADD',
    l_add: 'Left ADD',
    r_koridor: 'Right Koridor',
    l_koridor: 'Left Koridor',
    r_prisma: 'Right Prisma',
    l_prisma: 'Left Prisma',
    l_base_type: 'Left Base',
    r_base_type: 'Right Base',
    r_base_value: 'Right Base Value',
    l_base_value: 'Left Base Value',
    r_base_curve: 'Right Base Curve',
    l_base_curve: 'Left Base Curve',
    lebar_patrun: 'Lebar Patrun',
    tinggi_patrun: 'Tinggi Patrun',
    diagonal: 'Diagonal',
    dbl_bridge: 'Bridge',
    right_mpd: 'MPD R',
    left_mpd: 'MPD L',
    pv_seg_height: 'PV / SH',
    faset: 'Faset',
    corridor: 'Koridor',
    tinting_remarks: 'Tinting Remarks',
    paturn_hoya: 'Pola Patrun PENTAX',
    remarks: 'Remarks',
    frame_type: 'Jenis Frame',
    frame_brand: 'Merk Frame'
};

export default standardParams;
